import {SpinButton} from '@fluentui/react-components';
import useClasses from './ProvisionCapacityPanel.styles';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Capacity, OverageState, useGetCapacityByName, usePatchCapacity} from '@/api/capacities';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';
import {GEO_DATA} from '@/components/App.constants';
import {GeoName} from '@/components/App.types';
import {useTranslation} from 'react-i18next';
import {ARIA_LABELS, CONTROL_LABELS, STATIC_CONTENT} from './ChangeCapacity.constants';
import formatCurrency from '@/util/currencyFormatterUtil';
import {AnnounceText} from '@/components/ui/AnnounceText';
import {useFeatureFlag} from '@/api/user';
import OveragePanel from './OveragePanel';
import {OVERAGE_SCU_COST} from '../UsageDashboard/UsageDashboard.constants';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import {on} from 'events';
interface OverageUnitDetails {
    useOverage: boolean;
    unlimitedOverage: boolean;
    limitValue: number;
}
interface ProvisionCapacityPanelProps {
    isOverageEnabled?: boolean;
    overageUnitDetails: OverageUnitDetails;
    capacityToOverageDetails: OverageUnitDetails;
    onCapacityValueChanged: (value: number) => void;
    setOverageUnitDetails: (value: OverageUnitDetails) => void;
    setCapacityToOverageDetails: (value: OverageUnitDetails) => void;
    // note that geo is optional, but not including a value for geo will prevent the updating of estimated cost value
    geo?: string;
    capacity?: Capacity;
    capacityValue: number;
}

export default function ProvisionCapacityPanel(props: ProvisionCapacityPanelProps) {
    const classes = useClasses();

    const {t: tAdmin} = useTranslation('admin');

    const {estimatedMonthlyCost, maxCapacityValue, minCapacityValue} = useMemo(() => {
        let maxCapacityValue = 1000;
        let minCapacityValue = 0;
        let estimatedMonthlyCostValue = 0;

        if (props.geo) {
            const geo = GEO_DATA[props.geo.toUpperCase() as GeoName];
            estimatedMonthlyCostValue = geo.pricing * 24 * 30 * props.capacityValue;
            maxCapacityValue = geo.maxCapacity;
            minCapacityValue = geo.minCapacity;
        }

        const estimatedMonthlyCost = formatCurrency(estimatedMonthlyCostValue, 'USD', 'en-US');

        return {estimatedMonthlyCost, maxCapacityValue, minCapacityValue};
    }, [props.capacity, props.capacityValue]);

    useEffect(() => {
        if (props.isOverageEnabled && props.capacity && Boolean(props.capacity.properties)) {
            props.setOverageUnitDetails({
                useOverage:
                    Boolean(props.capacity.properties.overageState) &&
                    props.capacity.properties.overageState !== OverageState.None,
                unlimitedOverage: props.capacity.properties.overageState === OverageState.Unlimited,
                limitValue: props.capacity.properties.overageAmount || 0,
            });
            props.setCapacityToOverageDetails({
                useOverage:
                    Boolean(props.capacity.properties.overageState) &&
                    props.capacity.properties.overageState !== OverageState.None,
                unlimitedOverage: props.capacity.properties.overageState === OverageState.Unlimited,
                limitValue: props.capacity.properties.overageAmount || 0,
            });
        }
    }, [props.capacity]);

    // Actions to take on close/dismiss of change capacity dialog
    const setSpinValue = useCallback(
        (event: any, data: any) => {
            if (data.value !== undefined) {
                props.onCapacityValueChanged(data.value);
            } else if (data.displayValue !== undefined) {
                const newValue = parseFloat(data.displayValue);
                if (
                    !Number.isNaN(newValue) &&
                    newValue >= minCapacityValue &&
                    newValue <= maxCapacityValue
                ) {
                    props.onCapacityValueChanged(newValue);
                }
            }
        },
        [props.onCapacityValueChanged],
    );
    const isGeoSelected = props.geo == '';
    return (
        <div>
            <div className={classes.numericSection}>
                <div className={classes.spinButtonSection}>
                    <SpinButton
                        data-test-id="capacity-units-spin-button"
                        min={minCapacityValue}
                        max={maxCapacityValue}
                        value={props.capacityValue}
                        onChange={setSpinValue}
                        className={classes.spinButton}
                        aria-label={tAdmin(ARIA_LABELS.CHANGE_CAPACITY_UNITS)}
                        disabled={isGeoSelected}
                    />
                </div>
                <div className={classes.estimatedMonthlyCostSection}>
                    <AnnounceText textProps={{as: 'span'}} ariaLive="polite">
                        <div>{tAdmin(STATIC_CONTENT.ESTIMATED_MONTHLY_COST)}</div>
                        <div>
                            <span className={classes.costPerMonth}>{`${tAdmin(
                                STATIC_CONTENT.USD,
                            )} ${estimatedMonthlyCost}`}</span>
                            {`${tAdmin(STATIC_CONTENT.MONTH)}`}
                        </div>
                    </AnnounceText>
                </div>
            </div>

            {props.isOverageEnabled && (
                <div className={classes.changeCapacityOverageSection}>
                    <OveragePanel
                        needsOverageUnits={props.overageUnitDetails.useOverage}
                        unlimitedOverage={props.overageUnitDetails.unlimitedOverage}
                        onOverageChange={(useOverage) => {
                            props.setOverageUnitDetails({
                                ...props.overageUnitDetails,
                                ...useOverage,
                            });
                        }}
                        limitValue={props.overageUnitDetails.limitValue}
                        scuCost={OVERAGE_SCU_COST}
                    />
                </div>
            )}
        </div>
    );
}
