import React, {useState} from 'react';
import {Button, Tooltip} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {ClipboardBulletListRegular, LaptopPersonRegular} from '@fluentui/react-icons';

interface AgentViewButtonProps {
    showAgent: boolean;
    setShowAgent: (show: boolean) => void;
}

function AgentViewButton({showAgent, setShowAgent, ...props}: AgentViewButtonProps) {
    const {t} = useTranslation('session');
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <Tooltip
            visible={showTooltip}
            content={showAgent ? t('SessionViewButton') : t('AgentViewButton')}
            relationship="label"
        >
            <Button
                appearance="transparent"
                {...props}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onFocus={() => setShowTooltip(true)}
                onBlur={() => setShowTooltip(false)}
                aria-label={showAgent ? t('SessionViewButton') : t('AgentViewButton')}
                icon={showAgent ? <ClipboardBulletListRegular /> : <LaptopPersonRegular />}
                onClick={() => {
                    setShowTooltip(false);
                    setShowAgent(!showAgent); // Toggle the Agent component
                }}
                data-testid="agent-view-button"
            />
        </Tooltip>
    );
}

export default AgentViewButton;
