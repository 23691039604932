import React, {useMemo} from 'react';
import useClasses, {useStaticStyles} from './App.styles';
import {mergeClasses} from '@fluentui/react-components';
import {useLayout} from '@/components/ui/Layout';
import {isUntrustedApi} from '@/api/api';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';

export default function Content({
    children,
    isFirstRun,
    hasPaneContent,
}: {
    children: React.ReactNode;
    isFirstRun: boolean;
    hasPaneContent: boolean;
}) {
    useStaticStyles();
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {sidePanel} = useLayout();
    const {width} = sidePanel;
    const contentWidth = 100 - width;
    const mainClasses = mergeClasses(
        classes.main,
        isFirstRun && classes.firstRunMain,
        scrollClasses.colorNeutralBackground2,
    );
    const dynamicWidth = useMemo(() => {
        if (hasPaneContent && sidePanel.open) {
            return {style: {width: `${contentWidth}%`, height: `calc(100vh - 48px)`}};
        } else {
            return {};
        }
    }, [contentWidth, hasPaneContent, sidePanel.open]);

    return (
        <main
            className={mainClasses}
            data-testid="main-content"
            id="main-content"
            {...dynamicWidth}
        >
            <div
                className={mergeClasses(
                    classes.contentWrapper,
                    isUntrustedApi && classes.untrustedWrapper,
                )}
            >
                {children}
            </div>
        </main>
    );
}
