import {IVerticalStackedChartProps} from '@fluentui/react-charting';

export const chartDataExtractor = (chartConfiguration: IVerticalStackedChartProps) => {
    const getChartDataItem = (key: string) =>
        chartConfiguration.chartData.find((item) => item.legend === key);

    const doesChartDataItemExist = (key: string) => !!getChartDataItem(key);

    return {
        getChartDataItem,
        doesChartDataItemExist,
    };
};
