import {useTranslation} from 'react-i18next';
import useClasses from './BarChartHoverCard.styles';
import {
    BAR_CHART_ABOVE_OVERAGE_UNITS_USAGE,
    BAR_CHART_BASE_UNITS_USAGE,
    BAR_CHART_HIGH_DEMAND_UNITS_USAGE,
    BAR_CHART_PROVISIONED,
} from '../../UsageDashboard.constants';
import {useMemo} from 'react';
import {mergeClasses} from '@griffel/react';

interface HoverCardUsageProps {
    usageValue: string;
    usageType: 'base' | 'highDemand' | 'aboveOverage' | 'provisioned';
}

export default function HoverCardUsage(props: HoverCardUsageProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');

    const content = useMemo(() => {
        const usageType = props.usageType;
        switch (usageType) {
            case 'highDemand':
                return t(BAR_CHART_HIGH_DEMAND_UNITS_USAGE);
            case 'aboveOverage':
                return t(BAR_CHART_ABOVE_OVERAGE_UNITS_USAGE);
            case 'provisioned':
                return t(BAR_CHART_PROVISIONED);
            default:
                return t(BAR_CHART_BASE_UNITS_USAGE);
        }
    }, [props.usageType]);

    const barClass = useMemo(() => {
        const baseClass = classes.usageBar;
        var additionalClass = classes.regularBar;

        if (props.usageType === 'highDemand') {
            additionalClass = classes.highDemandBar;
        } else if (props.usageType === 'aboveOverage') {
            additionalClass = classes.aboveOverageBar;
        }

        return mergeClasses(baseClass, additionalClass);
    }, [props.usageType]);

    return (
        <div data-testid="bar-chart-hover-card" className={classes.usageBox}>
            <div className={barClass} />
            <div className={classes.usageValueBox}>
                <div>{content}</div>
                <div className={classes.usageValue}>{props.usageValue}</div>
            </div>
        </div>
    );
}
