import {PromptbookDescriptor, PromptbookPrompts, SkillInputs} from '@/api/promptbooks';
import {Prompt, PromptCategory, PromptInputs, PromptType} from '@/api/prompts';
import {PromptSuggestionDescriptor} from '@/api/prompts/suggestedPrompts.types';
import {SkillDescriptor} from '@/api/skills';

export interface PromptBarSubmitOptions {
    skillsets?: string[];
    featureFlags?: string[];
    promptType: PromptType;
    promptCategory?: PromptCategory | PromptCategory.UserGenerated;
    content?: string;
    skillName?: string;
    inputs?: PromptInputs;
    defaultAgent?: string;
    promptbookId?: string;
    promptbookPrompts?: PromptbookPrompts[] | null;
    skillInputs?: {[key: string]: SkillInputs};
}

export enum ScreenName {
    Home = 'home',
    Session = 'session',
}

export interface PromptBarProps {
    onSubmit?: ({content, skillsets, featureFlags}: PromptBarSubmitOptions) => void;
    onCancel?: () => void;
    // TODO: Remove this when promptbook modal is decoupled from the prompt bar
    onPromptbookCancel?: () => void;
    handleSuggestedPromptClick?(prompt: PromptSuggestionDescriptor | undefined): void;
    className?: string;
    enableSkillsetConnector?: boolean;
    enableSupportAssistance?: boolean;
    editMode?: boolean;
    disabled?: boolean;
    defaultValue?: string | undefined;
    defaultSkill?: SkillDescriptor | undefined;
    defaultPrompt?: Prompt | undefined;
    defaultSkillsets?: string[];
    defaultPromptbook?: PromptbookDescriptor | undefined;
    defaultPromptbookPrompts?: PromptbookPrompts[] | undefined;
    // When `defaultValue` and this are truthy, automatically submit the prompt. (headless)
    submitDefaultValue?: boolean;
    // When true will render the Promptbar and its components in fluid mode (no max width)
    fluid?: boolean;
    autoFocus?: boolean;
    screenName?: ScreenName;
    restoreFocusSourceAttribute?: any;
    suggestedPrompt?: PromptSuggestionDescriptor | undefined;
}
