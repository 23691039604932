import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {SkillsetRequirementsCheckResult} from './skills.types';

export default function useCheckSkillsetRequirements(
    skillsetName: string,
    successCallback: (skillsetRequirementsCheckResult: SkillsetRequirementsCheckResult) => void,
) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['skillsets', 'requirements', skillsetName],
        enabled: !!skillsetName,
        queryFn: async () => {
            return await customFetch<SkillsetRequirementsCheckResult>(
                `/skillsets/${skillsetName}/checkRequirements`,
                {
                    method: 'GET',
                },
            );
        },
        onSuccess: successCallback,
    });
}
