import {useTranslation} from 'react-i18next';
import useClasses from './BarChartHoverCard.styles';
import {IVerticalStackedChartProps} from '@fluentui/react-charting';
import {CAPACITY_BUCKETS} from '../../UsageDashboard.constants';
import HoverCardUsage from './HoverCardUsage';
import {useMemo} from 'react';
import v2ChartConfigurator from '../ChartConfigurators/v2ChartConfigurator';
import {chartDataExtractor} from '../BarChartHoverCard.utils';

export interface ConsumptionModelHoverCardProps {
    calloutData: IVerticalStackedChartProps;
}

export default function ConsumptionModelHoverCard(props: ConsumptionModelHoverCardProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');

    const {getChartDataItem} = chartDataExtractor(props.calloutData);

    const belowThresholdAmount = getChartDataItem(CAPACITY_BUCKETS.BELOW_THRESHOLD)?.data;
    const aboveThresholdAmount = getChartDataItem(CAPACITY_BUCKETS.ABOVE_THRESHOLD)?.data;

    const chartConfigurator = new v2ChartConfigurator(t);

    const matchingStatus = useMemo(() => {
        return Object.entries(chartConfigurator.getMapForOnHoverMatching()).find(
            ([key]) => key === props.calloutData.xAxisCalloutData,
        );
    }, [props.calloutData.xAxisCalloutData]);

    const usageStatus = props.calloutData.xAxisCalloutData && matchingStatus;

    return (
        <div className={classes.usageHoverCardRoot} data-testid="consumption-model-hover-card">
            {usageStatus && (
                <div className={classes.usageStatus} data-testid="usage-status">
                    <div className={classes.usageStatusIcon}>{matchingStatus[1].icon}</div>
                    <div className={classes.usageStatusText}>{t(matchingStatus[1].status)}</div>
                </div>
            )}
            <>
                {parseFloat(String(aboveThresholdAmount ?? 0)) > 0 && (
                    <HoverCardUsage
                        usageValue={String(aboveThresholdAmount)}
                        usageType={'highDemand'}
                    ></HoverCardUsage>
                )}
                <HoverCardUsage
                    usageValue={String(belowThresholdAmount)}
                    usageType={'base'}
                ></HoverCardUsage>
            </>
        </div>
    );
}
