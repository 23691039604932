import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UserPreferences} from './user.types';
export default function useUpdatePreferredEmbeddedWorkspace() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const updatePreferredEmbeddedWorkspace = async (
        newPreferredEmbeddedWorkspace: string | null,
    ) => {
        return await customFetch<UserPreferences>(`/userPreferences/preferredEmbeddedWorkspace`, {
            method: 'PUT',
            body: JSON.stringify({preferredEmbeddedWorkspace: newPreferredEmbeddedWorkspace}),
            headersFromOptions: {
                'Content-Type': 'application/json',
            },
        });
    };
    return useMutation({
        mutationFn: updatePreferredEmbeddedWorkspace,
        onSettled: (updatedPreferences, error, variables, context) => {
            if (!error) {
                queryClient.invalidateQueries(['userPreferences']);
            }
        },
    });
}
