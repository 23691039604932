import {useMutation, useQueryClient} from '@tanstack/react-query';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import {Workspace} from './workspaces.types';
import securityGraphApi from '@/api/securityGraph.api';
/**
 * Hook to delete a workspace
 * @returns GetWorkspaceByNameResponse
 */
interface DeleteWorkspaceParams {
    targetWorkspace: Workspace;
}

export default function useDeleteWorkspace() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({targetWorkspace}: DeleteWorkspaceParams) => {
            const getUrl = () =>
                securityGraphApi({
                    path: ``,
                    version: '2023-12-01-preview',
                    targetWorkspace: targetWorkspace,
                });
            try {
                return await customFetch<Response>(
                    getUrl(),
                    {
                        method: 'DELETE',
                        body: targetWorkspace,
                        scopes: RESOURCE_SCOPES.FIDELIS,
                    },
                    true,
                );
            } catch (e) {
                throw e;
            }
        },
        onSuccess: (data, variables, context) => {},
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(['workspaces']);
            queryClient.removeQueries(['workspaces', variables.targetWorkspace.name]);
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
