import {SkillInputDescriptor} from '../skills';

export type Prompt = {
    sessionId: string;
    promptId: string;
    createdAt: string;
    promptType: PromptType;
    content?: string;
    skillName?: string;
    skillsets?: string[];
    inputs?: PromptInputs;
    promptInputs?: PromptInputs;
    skillInputs?: PromptInputs;
    skillInputDescriptors?: SkillInputDescriptor[];
    latestEvaluationId?: string;
    lastCompletedEvaluationId?: string;
    promptSuggestions: PromptSuggestions;
    promptbookExecutionId?: string;
    promptCategory?: PromptCategory | PromptCategory.UserGenerated;

    // Only used to pre-emptively queue a loading state.
    // TODO: Come up with a better way of handling this.
    __isQueued?: boolean;
};

export enum PromptType {
    Unknown = 'Unknown',
    Prompt = 'Prompt',
    Context = 'Context',
    Skill = 'Skill',
    SkillDirect = 'SkillDirect',
}

export type GroupedPrompt = {
    type: 'Prompt' | 'Promptbook';
    prompts: Prompt[];
};

export enum PromptCategory {
    UserGenerated = 'UserGenerated',
    SystemGenerated = 'SystemGenerated',
    PromptLibrary = 'PromptLibrary',
}

export type PromptInputs = {[key: string]: string};

export type PromptSuggestions = {
    suggestions: Array<string>;
    history: Array<string>;
};

export type CreatePromptRequest = {
    sessionId: string;
    promptType: PromptType;
    content?: string;
    source?: string;
    skillName?: string;
    inputs?: PromptInputs;
    promptCategory?: PromptCategory | PromptCategory.UserGenerated;
};

export type UpdatePromptRequest =
    | CreatePromptRequest & {
          promptId: string;
      };

export type GetPromptRequest = {
    sessionId: string;
    promptId: string;
};

export type GetPromptsRequest = {
    sessionId: string;
    lastPromptId?: string;
};

export type GetGroupedPromptRequest = {
    prompts: Prompt[];
};

export type RerunPromptsRequest = {
    sessionId: string;
    promptIds: string[];
};

export type GetStarterPromtsRequest = {
    skillsets?: string[];
    personas?: string[];
};

export type Persona = {
    id: string;
    name: string;
};

export type AvailablePersona = {
    personaList: Persona[];
};

export type GetPromptSuggestionsRequest = GetPromptRequest;
export type DeletePromptRequest = GetPromptRequest;
export type DeletePromptsRequest = RerunPromptsRequest;
export type GetPromptsFromIdRequest = RerunPromptsRequest;
