import React, {useEffect, useRef, useState} from 'react';
import {Label, Dropdown, Option, useId} from '@fluentui/react-components';
import usePreferenceClasses from '../../Navigation/Preferences.styles';
import {useTranslation} from 'react-i18next';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import ErrorSettingWorkspace from '@/components/Navigation/Breadcrumbs/ErrorSettingWorkspace';
import useGetPreferredEmbeddedWorkspace from '@/api/user/useGetPreferredEmbeddedWorkspace';
import useUpdatePreferredEmbeddedWorkspace from '@/api/user/useUpdatePreferredEmbeddedWorkspace';
import SwitchPreferredWorkspaceConfirmation from '@/components/SwitchPreferredWorkspaceConfirmation';

function PreferredWorkspaceDropdown() {
    const preferenceClasses = usePreferenceClasses();
    const {t} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const updatePreferredEmbeddedWorkspace = useUpdatePreferredEmbeddedWorkspace();
    const preferredWorkspaceId = useId('preferredWorkspace');
    // preferred workspace data
    const {data: preferredEmbeddedWorkspace} = useGetPreferredEmbeddedWorkspace();
    const [selectedWorkspace, setSelectedWorkspace] = useState<string | null>(
        preferredEmbeddedWorkspace || null,
    );
    const [tempSelectedWorkspace, setTempSelectedWorkspace] = useState<string | null>(null);
    const [
        isSwitchPreferredWorkspaceConfirmationOpen,
        setSwitchPreferredWorkspaceConfirmationOpen,
    ] = useState<boolean>(false);
    const {data: workspacesData} = useGetWorkspaces();
    // check if the preferred workspace is in the list of workspaces
    useEffect(() => {
        if (workspacesData?.value) {
            if (
                !workspacesData.value.some(
                    (workspace: Workspace) => workspace.name === preferredEmbeddedWorkspace,
                )
            ) {
                setSelectedWorkspace(null);
                updatePreferredEmbeddedWorkspace.mutate(null);
            }
        }
    }, [preferredEmbeddedWorkspace]);

    // change workspace
    const handleMenuItemClick = (workspace: Workspace) => {
        if (workspace.name === selectedWorkspace) {
            return;
        }
        setTempSelectedWorkspace(workspace.name);
        setSwitchPreferredWorkspaceConfirmationOpen(true);
    };
    const onSwitchPreferredWorkspace = () => {
        updatePreferredEmbeddedWorkspace.mutate(tempSelectedWorkspace, {
            onSuccess: () => {
                setSelectedWorkspace(tempSelectedWorkspace);
            },
            onError: (error) => {
                setErrorPreferredWorkspaceDialogOpen(true);
            },
        });
    };
    const buttonRef = useRef<HTMLButtonElement>(null);
    // make a ref for the dropdown to focus on it after closing the dialog

    const [isErrorPreferredWorkspaceDialogOpen, setErrorPreferredWorkspaceDialogOpen] =
        useState<boolean>(false);

    return (
        <div className={preferenceClasses.field}>
            <h3>{t('SettingsPreferredWorkspaceHeading')}</h3>
            <Label className={preferenceClasses.label}>
                {t('SettingsPreferredWorkspaceDescription')}
            </Label>
            <div className={preferenceClasses.dropdownWrapper}>
                <Dropdown
                    id={preferredWorkspaceId}
                    placeholder={t('SettingsPreferredWorkspaceDropdownPlaceholder')}
                    value={selectedWorkspace || undefined}
                    selectedOptions={[selectedWorkspace || '']}
                    data-testid="preferred-workspace-dropdown"
                    ref={buttonRef}
                >
                    {workspacesData?.value?.map((workspace: Workspace, index: number) => (
                        <Option
                            data-testid="preferred-workspace-dropdown-option"
                            key={index}
                            onClick={() => handleMenuItemClick(workspace)}
                        >
                            {workspace.name}
                        </Option>
                    ))}
                </Dropdown>
                <ErrorSettingWorkspace
                    isOpen={isErrorPreferredWorkspaceDialogOpen}
                    onClose={() => {
                        setErrorPreferredWorkspaceDialogOpen(false);
                        document.getElementById(preferredWorkspaceId)?.focus();
                    }}
                    title={tAdmin('ManageWorkspaces.Errors.SetPreferredWorkspace')}
                ></ErrorSettingWorkspace>
                <SwitchPreferredWorkspaceConfirmation
                    isSwitchPreferredWorkspaceConfirmationOpen={
                        isSwitchPreferredWorkspaceConfirmationOpen
                    }
                    onClose={() => {
                        setSwitchPreferredWorkspaceConfirmationOpen(false);
                        if (buttonRef.current) {
                            buttonRef.current.focus();
                        }
                    }}
                    onConfirm={onSwitchPreferredWorkspace}
                ></SwitchPreferredWorkspaceConfirmation>
            </div>
        </div>
    );
}

export default PreferredWorkspaceDropdown;
