const excludedPaths = [
    'auth',
    'datashare',
    'userinfo',
    'users/features',
    'userPreferences',
    'workspaces',
    'securityplatform',
];

export const isUrlPathExcludedFromMsgRouting = (url: string): boolean => {
    // check if lowerCaseUrl includes any string in the excludedPaths list
    return excludedPaths.some((path) => {
        const regex = new RegExp(`(^|/|\\?)${path}(/|\\?|$)`, 'i');
        return regex.test(url);
    });
};
