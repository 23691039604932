import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: tokens.spacingHorizontalM,
    },
    rowGap: {
        rowGap: tokens.spacingHorizontalM,
    },
    title: {
        ...shorthands.flex(0, 0, 'auto'),
        ...shorthands.padding(0, tokens.spacingHorizontalM, 0, tokens.spacingHorizontalXXL),
        fontSize: tokens.fontSizeBase300,
        position: 'relative',
    },
    promptContent: {
        ...shorthands.padding(0, tokens.spacingHorizontalXXXL, 0, 0),
        fontSize: tokens.fontSizeBase300,
        position: 'relative',
        wordWrap: 'break-word',
    },
    skillIcon: {
        position: 'absolute',
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightBold,
        left: 0,
    },
    optionalFieldsButton: {
        border: 'none',
        backgroundColor: tokens.colorNeutralBackground5,
        color: tokens.colorNeutralForeground2,
        ...shorthands.padding(0, 0, tokens.spacingVerticalS, 0),
        fontSize: tokens.fontSizeBase100,
        display: 'flex',
        position: 'relative',
    },
    inputs: {
        display: 'flex',
        position: 'relative',
        marginBottom: tokens.spacingVerticalL,
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalXS,
        ...shorthands.flex(1, 1, 'auto'),
        maxHeight: '60vh',
        '@media (min-width: 640px) and (max-width: 1023px)': {
            maxHeight: '45vh',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            maxHeight: '40vh',
        },
        '@media (min-width: 321px) and (max-width: 479px)': {
            maxHeight: '50vh',
        },
        '@media (max-width: 320px)': {
            maxHeight: '20vh',
        },
        overflowY: 'auto',
        flex: 'auto',
        scrollbarWidth: 'none',
    },
    largeIcon: {
        position: 'absolute',
        fontSize: tokens.fontSizeBase300,
        bottom: '8px',
        left: '84px',
    },
    label: {
        fontSize: tokens.fontSizeBase200,
    },
    promptbookLibraryLabel: {
        width: 'fit-content',
        marginBottom: tokens.spacingVerticalS,
        ...shorthands.border('0px', 'none', 'transparent'),
        ...shorthands.outline('0px'),
        color: tokens.colorNeutralForeground1,
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        fontFamily: tokens.fontFamilyBase,
        backgroundColor: tokens.colorNeutralStroke1,
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
        ...shorthands.borderWidth('0px'),
        ...shorthands.padding(tokens.spacingHorizontalXS),
        wordWrap: 'break-word',
    },
    promptbookLibraryGap: {
        marginBottom: tokens.spacingVerticalXS,
    },
});
