import {PromptProps} from './PromptbookPrompt.types';
import useClasses from './PromptbookPrompt.styles';
import PromptbookPromptForm from '../promptbooks/PromptbookPromptForm';

export default function PromptbookPrompt(props: PromptProps) {
    const {prompt} = props;
    const classes = useClasses();
    const skillInputDescriptors = prompt.skillInputDescriptors ?? [];

    return (
        <div className={classes.promptLabel} data-test-id="promptbook-prompt" tabIndex={0}>
            <div className={classes.promptNumber}>{props.index ? props.index! + 1 : 1}</div>
            <div>
                <PromptbookPromptForm
                    promptContent={prompt.content}
                    skillName={prompt.skillName}
                    skillInputs={prompt.skillInputs}
                    promptInputs={prompt.promptInputs}
                    skillInputDescriptors={skillInputDescriptors}
                    isModal={true}
                    displayView={true}
                    checkValidity={false}
                />
            </div>
        </div>
    );
}
