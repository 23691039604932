import React, {useRef} from 'react';
import {
    Caption1,
    Divider,
    MenuGroupHeader,
    MenuItem,
    mergeClasses,
} from '@fluentui/react-components';
import {PromptBarMenuChildListProps} from './PromptBarMenu.types';
import useClasses from './PromptBarMenu.styles';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {useTranslation} from 'react-i18next';

export default function PromptBarMenuShowAllList<T>({
    onSelect,
    lists,
    noMatches,
    getName,
    getDisplayName,
    getDescription,
    header,
    tabIndex,
    getGroupLabel,
    directSkillMenu,
}: PromptBarMenuChildListProps<T>) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();
    const {groupedList, skillsetPreviewStateMap} = lists;
    let nextTabIndex = tabIndex;
    const {t} = useTranslation('promptbar');

    return (
        <div
            className={mergeClasses(
                classes.showAllContainer,
                scrollClasses.colorNeutralBackground1,
            )}
            style={{
                maxHeight: directSkillMenu ? '450px' : undefined,
                maxWidth: directSkillMenu ? '550px' : undefined,
            }}
        >
            {header}
            {Object.keys(groupedList).length > 0 ? (
                Object.keys(groupedList)
                    .sort()
                    .map((key, groupIndex) => {
                        const groupItems = groupedList[key];
                        return (
                            <React.Fragment key={key}>
                                {groupIndex > 0 && <Divider />}
                                <MenuGroupHeader className={classes.menuGroupHeader}>
                                    <div className={classes.menuGroupTitle}>
                                        <span
                                            data-testid="promptbar-menu-title"
                                            className={classes.menuGroupTitleText}
                                        >
                                            {key}
                                        </span>
                                        {/** Label for plugin header */}
                                        {getGroupLabel?.(key)}
                                    </div>
                                </MenuGroupHeader>
                                {groupItems.map((result, i) => (
                                    <MenuItem
                                        role="menuitem"
                                        tabIndex={nextTabIndex && nextTabIndex++}
                                        key={i.toString()}
                                        onClick={() => onSelect?.(result)}
                                        className={classes.menuItem}
                                    >
                                        <div className={classes.skillName}>
                                            {getName(result) || ''}
                                            {getDisplayName?.(result) && (
                                                <span className={classes.skillDisplayName}>
                                                    : {getDisplayName?.(result) || ''}
                                                </span>
                                            )}
                                        </div>
                                        <Caption1 className={classes.skillDescription}>
                                            {getDescription(result) || ''}
                                        </Caption1>
                                    </MenuItem>
                                ))}
                            </React.Fragment>
                        );
                    })
            ) : (
                <div className={classes.noMatches}>{noMatches || ''}</div>
            )}
        </div>
    );
}
