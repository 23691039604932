import {WorkspaceFormProps} from './WorkspaceForm.types';
import {DialogContent, DialogActions, DialogTrigger, Button} from '@fluentui/react-components';
import React, {useCallback, useState} from 'react';
import {useDeleteWorkspace} from '@/api/workspaces';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {useTranslation} from 'react-i18next';
import useClasses from './WorkspaceForm.styles';
import useButtonClasses from '@/components/ui/Buttons/Button.styles';
import {FormErrorMessageBar} from './FormErrorMessageBar';
import useGetCurrentWorkspace from '@/api/user/useGetCurrentWorkspace';
import ErrorSettingWorkspace from '@/components/Navigation/Breadcrumbs/ErrorSettingWorkspace';
import {writeToSessionStorage} from '@/util/sessionStorage.util';
import {WorkspaceSessionData} from '../WorkspacesProvider';
import {WORKSPACE_NAMESPACE} from '../WorkspacesProvider/workspaces.constants';
import {useNavigate} from 'react-router-dom';
import useUpdateCurrentWorkspace from '@/api/user/useUpdateCurrentWorkspace';
import {useGetWorkspaces} from '@/api/workspaces';
import WorkspacesLoadingOverlay from '../ManageWorkspaces/WorkspacesLoadingOverlay';
import useGetPreferredEmbeddedWorkspace from '@/api/user/useGetPreferredEmbeddedWorkspace';
import useUpdatePreferredEmbeddedWorkspace from '@/api/user/useUpdatePreferredEmbeddedWorkspace';
import {useGetUserInfo} from '@/api/app';

function Delete(props: WorkspaceFormProps) {
    const {workspace, onClose, onCancel, onDelete} = props;
    const classes = useClasses();
    const buttonClasses = useButtonClasses();
    const {t: tCommon} = useTranslation('common');
    const {mutate: deleteWorkspace} = useDeleteWorkspace();
    const workspaceName = workspace?.name || '';
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {data: currentWorkspace} = useGetCurrentWorkspace({
        enabled: Boolean(isWorkspacesTestingEnabled),
    });
    const [isMessageBarVisible, setIsMessageBarVisible] = useState(false);
    const [overlay, setOverlay] = useState<boolean>(false);
    const {t} = useTranslation('admin');
    const navigate = useNavigate();
    const {data: workspaces, refetch: refetchWorkspaces} = useGetWorkspaces();
    const updateUserPreferences = useUpdateCurrentWorkspace();
    const [isSetErrorSwitchingWorkspaceDialogOpen, setErrorSwitchingWorkspaceDialogOpen] =
        useState<boolean>(false);
    const updatePreferredEmbeddedWorkspace = useUpdatePreferredEmbeddedWorkspace();
    const {data: preferredWorkspace, isFetched} = useGetPreferredEmbeddedWorkspace({
        enabled: Boolean(isWorkspacesTestingEnabled),
    });
    const {data: userInfo, isFetched: isUserInfoFetched} = useGetUserInfo();
    const handleDelete = useCallback(() => {
        setOverlay(true);
        if (workspaceName && workspace) {
            deleteWorkspace(
                {
                    targetWorkspace: workspace,
                },
                {
                    onSuccess: () => {
                        onClose?.();
                        onDelete?.();
                        setOverlay(false);
                        setIsMessageBarVisible(false);
                        if (workspaceName === currentWorkspace) {
                            // switch to random workspace
                            // remove old workspace from session storage
                            sessionStorage.removeItem(WORKSPACE_NAMESPACE);
                            // refetch workspaces and route to the first one
                            refetchWorkspaces().then((result) => {
                                const workspaces = result?.data?.value;
                                if (!workspaces || workspaces.length === 0) {
                                    if (userInfo?.isAdmin) {
                                        navigate('/manage-workspaces?openCreateWorkspace=true');
                                    } else {
                                        // refresh page
                                        window.location.reload();
                                    }
                                } else {
                                    // get first workspace in result
                                    const firstWorkspace = result?.data?.value?.[0];
                                    if (firstWorkspace) {
                                        // go to switching workspace loading page
                                        navigate(`/workspace/${firstWorkspace.name}`);
                                        const workspaceData = {
                                            name: firstWorkspace.name,
                                            path: firstWorkspace.path,
                                            managementUrl: firstWorkspace.managementUrl,
                                            podId: firstWorkspace.path?.split('/')[1],
                                        };
                                        updateUserPreferences.mutate(
                                            {
                                                currentWorkspace: firstWorkspace.name,
                                            },
                                            {
                                                onSuccess: () => {
                                                    writeToSessionStorage<WorkspaceSessionData>(
                                                        WORKSPACE_NAMESPACE,
                                                        workspaceData,
                                                    );
                                                    console.log(
                                                        'Successfully updated current workspace',
                                                    );
                                                    setErrorSwitchingWorkspaceDialogOpen(false);
                                                },
                                                onError: (error: any) => {
                                                    console.log(
                                                        'Error updating user preferences current workspace: ',
                                                        error,
                                                    );
                                                    setErrorSwitchingWorkspaceDialogOpen(true);
                                                    return;
                                                },
                                            },
                                        );
                                        setTimeout(() => {
                                            // route back to home page to exit loading screen then refresh
                                            navigate(`/`);
                                            window.location.reload();
                                        }, 3000);
                                    }
                                }
                            });
                        }
                        // check if the workspace being deleted is the preferred workspace
                        if (workspaceName === preferredWorkspace && isFetched) {
                            updatePreferredEmbeddedWorkspace.mutate(null);
                        }
                    },
                    onError: (error: any) => {
                        console.error({error});
                        setOverlay(false);
                        setIsMessageBarVisible(true);
                    },
                },
            );
        }
    }, [deleteWorkspace, onClose, workspaceName]);

    return (
        <>
            {isMessageBarVisible && <FormErrorMessageBar messageTitle={'delete'} />}
            <DialogContent>
                {t('Workspaces.Form.Delete.Confirmation', {workspaceName})}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div></div>
                <div className={classes.rightActions}>
                    <Button className={buttonClasses.redButton} onClick={handleDelete}>
                        {t('Workspaces.Form.Delete.DeleteButton', {workspaceName})}
                    </Button>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" onClick={onCancel}>
                            {tCommon('ButtonLabels.Cancel', {workspaceName})}
                        </Button>
                    </DialogTrigger>
                </div>
            </DialogActions>
            <ErrorSettingWorkspace
                isOpen={isSetErrorSwitchingWorkspaceDialogOpen}
                onClose={() => {
                    setErrorSwitchingWorkspaceDialogOpen(false);
                }}
                title={t('ManageWorkspaces.Errors.SwitchWorkspaces')}
            ></ErrorSettingWorkspace>
            {overlay && (
                <WorkspacesLoadingOverlay label={t('ManageWorkspaces.DeletingWorkspace')} />
            )}
        </>
    );
}

export default Delete;
