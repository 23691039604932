import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import {DismissIcon} from './ui/icons';
import {useTranslation} from 'react-i18next';
import useClasses from './SetPreferredWorkspaceDialog.styles';

interface SwitchPreferredWorkspaceConfirmationProps {
    isSwitchPreferredWorkspaceConfirmationOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export default function SwitchPreferredWorkspaceConfirmation(
    props: SwitchPreferredWorkspaceConfirmationProps,
) {
    const [isSetPreferredWorkspaceDialogOpen, setSetPreferredWorkspaceDialogOpen] =
        useState<boolean>(false);
    const {t} = useTranslation('common');
    const classes = useClasses();

    useEffect(() => {
        setSetPreferredWorkspaceDialogOpen(props.isSwitchPreferredWorkspaceConfirmationOpen);
    }, [props.isSwitchPreferredWorkspaceConfirmationOpen]);

    const handleClose = () => {
        props.onClose();
    };

    const handleSwitch = () => {
        props.onConfirm();
        handleClose();
    };

    return (
        <>
            <Dialog open={isSetPreferredWorkspaceDialogOpen} onOpenChange={handleClose}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label={t('ButtonLabels.Close')}
                                        icon={<DismissIcon />}
                                        onClick={handleClose}
                                    />
                                </DialogTrigger>
                            }
                        >
                            <div className={classes.titleContainer}>
                                <div>{t('SwitchPreferredWorkspaceTitle')}</div>
                            </div>
                        </DialogTitle>
                        <DialogContent className={classes.contentSection}>
                            <div>{t('SwitchPreferredWorkspaceDescription')}</div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-testid="go-to-settings-button"
                                appearance="primary"
                                aria-label={t('SwitchPreferredWorkspaceButton')}
                                onClick={() => {
                                    handleSwitch();
                                }}
                            >
                                {t('SwitchPreferredWorkspaceButton')}
                            </Button>
                            <Button
                                data-testid="cancel-button"
                                appearance="secondary"
                                aria-label={t('ButtonLabels.Cancel')}
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                {t('ButtonLabels.Cancel')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
}
