import React, {useEffect, useRef} from 'react';
import {
    Dialog,
    DialogBody,
    DialogSurface,
    Spinner,
    useFocusFinders,
} from '@fluentui/react-components';
import useClasses from './WorkspacesLoadingOverlay.styles';
import {useTranslation} from 'react-i18next';
/* Template spinner for worksapce related operations such as:
 * switching capacities
 * creating workspace
 * deleting workspace
 */
interface WorkspacesLoadingOverlayProps {
    label: string;
}
export default function WorkspacesLoadingOverlay(props: WorkspacesLoadingOverlayProps) {
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const {findFirstFocusable} = useFocusFinders();
    const classes = useClasses();
    const {t} = useTranslation('admin');

    useEffect(() => {
        if (dialogRef.current) {
            findFirstFocusable(dialogRef.current)?.focus();
        }
    }, [dialogRef, findFirstFocusable]);

    return (
        <Dialog open>
            <DialogSurface>
                <DialogBody className={classes.root} ref={dialogRef}>
                    <Spinner
                        labelPosition="below"
                        size="extra-large"
                        label={props.label}
                        tabIndex={0}
                        data-testid="workspaces-loading-overlay-spinner"
                    />
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
