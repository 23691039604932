import {HourlyCapacityUsage} from '@/api/capacities';
import {IVerticalStackedChartProps} from '@fluentui/react-charting';
import {IUsageChartConfigurator, V2FilteredChartUsageData} from '../VStackedBarChart.types';
import {
    getDayName,
    getHourlyTimeframe,
    getZonedDate,
    roundToDecimalPlaces,
} from '../../UsageDashboard.utils';
import {
    CAPACITY_BUCKETS,
    CAPACITY_LIMIT_TYPES,
    UNIT_PLURAL_TEXT,
    USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
} from '../../UsageDashboard.constants';
import {tokens} from '@fluentui/react-components';
import {getDefaultMapForHoverMatching} from './configurators.utils';

export default class defaultUsageChartConfigurator implements IUsageChartConfigurator {
    private translator!: Function;

    constructor(contentTranslator: Function) {
        this.translator = contentTranslator;
    }

    getMapForOnHoverMatching = (): {
        [key: string]: {icon: JSX.Element; status: string; ariaLabel: string};
    } => {
        return getDefaultMapForHoverMatching();
    };

    getChartConfiguration = (
        hourlyUsageMetrics: HourlyCapacityUsage,
    ): IVerticalStackedChartProps => {
        const usageData = this.getUsageData(hourlyUsageMetrics);
        return this.createGraphItem(hourlyUsageMetrics, usageData);
    };

    private getUsageData = (item: HourlyCapacityUsage): V2FilteredChartUsageData => {
        const roundedUsedCapacity = roundToDecimalPlaces(
            item.usedCapacity,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );

        let usageBucket = CAPACITY_LIMIT_TYPES.WITHIN_PROVISIONED_LIMITS;

        return {
            usage: roundedUsedCapacity,
            usageBucket: usageBucket,
        };
    };

    private createGraphItem = (
        item: HourlyCapacityUsage,
        usageData: V2FilteredChartUsageData,
    ): IVerticalStackedChartProps => {
        const {usage, usageBucket} = usageData;

        // Convert input UTC date to user timezone date
        const zonedDate = new Date(getZonedDate(item.aggregateStartTime));
        const stackAccessibilityData = this.getStackAccessibilityData(
            usageData,
            zonedDate,
            item.assignedCapacity,
        );

        return {
            chartData: [
                {
                    legend: CAPACITY_BUCKETS.WITHIN_PROVISIONED_LIMITS,
                    data: usage,
                    color: tokens.colorPaletteLightTealBorderActive,
                },
            ],
            xAxisPoint: zonedDate, // BarChart doesn't respect string for custom formatting
            xAxisCalloutData: usageBucket, // Using as a placeholder to show usage status on custom callout
            lineData: [],
            // Override is required as xAxisCalloutData, used as a placeholder for the custom hover card component, also gets read.
            // The usageBucket, being a key for translation text rather than suitable display text, needs to be replaced.
            stackCallOutAccessibilityData: {
                ariaLabel: stackAccessibilityData,
            },
        };
    };

    private getStackAccessibilityData = (
        usageData: ReturnType<typeof this.getUsageData>,
        date: Date,
        assignedCapacity: number,
    ): string => {
        const {usage, usageBucket} = usageData;

        const matchingStatus = Object.entries(this.getMapForOnHoverMatching()).find(
            ([key]) => key === usageBucket,
        );

        const usageBucketText = `${this.translator(matchingStatus?.[1].ariaLabel ?? '')}`;

        const usageText = `${this.translator(
            CAPACITY_BUCKETS.BELOW_THRESHOLD,
        )} ${usage} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const assignedCapacityText = `${this.translator(
            CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
        )} ${assignedCapacity} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const dayName = getDayName(date);
        const timeframe = getHourlyTimeframe(date);

        // Dots added to delay narrator.
        return `${usageBucketText}. ${dayName} from ${timeframe}. ${assignedCapacityText}`;
    };
}
