import {IVerticalStackedChartProps} from '@fluentui/react-charting';
import {CAPACITY_BUCKETS, UNIT_PLURAL_TEXT} from '../../UsageDashboard.constants';
import {getDayName, getHourlyTimeframe, getZonedDate} from '../../UsageDashboard.utils';
import {HourlyCapacityUsage} from '@/api/capacities';
import {tokens} from '@fluentui/react-components';
import {IUsageChartConfigurator, V2ChartUsageData} from '../VStackedBarChart.types';
import {
    generateConsumptionChartData,
    getConsumptionUsageData,
    getDefaultMapForHoverMatching,
} from './configurators.utils';

export default class v2ChartConfigurator implements IUsageChartConfigurator {
    private translator!: Function;

    constructor(contentTranslator: Function) {
        this.translator = contentTranslator;
    }

    getMapForOnHoverMatching = (): {
        [key: string]: {
            icon: JSX.Element;
            status: string;
            ariaLabel: string;
            supplementary?: string;
        };
    } => {
        return getDefaultMapForHoverMatching();
    };

    getChartConfiguration = (
        hourlyUsageMetrics: HourlyCapacityUsage,
    ): IVerticalStackedChartProps => {
        const usageData = this.getUsageData(hourlyUsageMetrics);
        return this.createGraphItem(hourlyUsageMetrics, usageData);
    };

    private getUsageData = (item: HourlyCapacityUsage): V2ChartUsageData =>
        getConsumptionUsageData(item);

    private createGraphItem = (
        item: HourlyCapacityUsage,
        usageData: V2ChartUsageData,
    ): IVerticalStackedChartProps => {
        const {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket} = usageData;

        // Convert input UTC date to user timezone date
        const zonedDate = new Date(getZonedDate(item.aggregateStartTime));
        const stackAccessibilityData = this.getStackAccessibilityData(
            usageData,
            zonedDate,
            item.assignedCapacity,
        );

        return {
            chartData: generateConsumptionChartData(
                usageAboveThreshold,
                usageBelowThreshold,
                usageThrottled,
            ),
            xAxisPoint: zonedDate, // BarChart doesn't respect string for custom formatting
            xAxisCalloutData: usageBucket, // Using as a placeholder to show usage status on custom callout
            lineData: [
                {
                    y: item.assignedCapacity,
                    legend: CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
                    color: tokens.colorStrokeFocus2,
                },
            ],
            // Override is required as xAxisCalloutData, used as a placeholder for the custom hover card component, also gets read.
            // The usageBucket, being a key for translation text rather than suitable display text, needs to be replaced.
            stackCallOutAccessibilityData: {
                ariaLabel: stackAccessibilityData,
            },
        };
    };

    private getStackAccessibilityData = (
        usageData: ReturnType<typeof this.getUsageData>,
        date: Date,
        assignedCapacity: number,
    ): string => {
        const {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket} = usageData;

        const matchingStatus = Object.entries(this.getMapForOnHoverMatching()).find(
            ([key]) => key === usageBucket,
        );

        const usageBucketText = `${this.translator(matchingStatus?.[1].ariaLabel ?? '')}`;

        const usageBelowThresholdText = `${this.translator(
            CAPACITY_BUCKETS.BELOW_THRESHOLD,
        )} ${usageBelowThreshold} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const usageAboveThresholdText = `${this.translator(
            CAPACITY_BUCKETS.ABOVE_THRESHOLD,
        )} ${usageAboveThreshold} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const usageThrottledText = `${this.translator(
            CAPACITY_BUCKETS.THROTTLED,
        )} ${usageThrottled} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const assignedCapacityText = `${this.translator(
            CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
        )} ${assignedCapacity} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const dayName = getDayName(date);
        const timeframe = getHourlyTimeframe(date);

        // Dots added to delay narrator.
        return `${usageBucketText}. ${dayName} from ${timeframe} . ${usageBelowThresholdText}. ${usageAboveThresholdText}. ${usageThrottledText}. ${assignedCapacityText}`;
    };
}
