import {WarningIcon} from '@/components/ui/icons';
import {
    CAPACITY_BUCKETS,
    CAPACITY_LIMIT_TYPES,
    CAPACITY_THRESHOLD_PERCENTAGE,
    USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
} from '../../UsageDashboard.constants';
import {HourlyCapacityUsage} from '@/api/capacities';
import {roundToDecimalPlaces} from '../../UsageDashboard.utils';
import {ConsumptionBasedChartUsageData} from '../VStackedBarChart.types';
import {IVSChartDataPoint} from '@fluentui/react-charting';
import {tokens} from '@fluentui/react-components';

export const getDefaultMapForHoverMatching = (): {
    [key: string]: {icon: JSX.Element; status: string; ariaLabel: string; supplementary?: string};
} => {
    return {
        [`${CAPACITY_LIMIT_TYPES.BELOW_THRESHOLD}`]: {
            icon: <></>,
            status: '',
            ariaLabel: `${CAPACITY_LIMIT_TYPES.BELOW_THRESHOLD}.UnitStatusAriaLabel`,
        },
        [`${CAPACITY_LIMIT_TYPES.ABOVE_THRESHOLD}`]: {
            icon: <WarningIcon color="#FFBA66" data-testid="above-threshold-warning-icon" />,
            status: `${CAPACITY_LIMIT_TYPES.ABOVE_THRESHOLD}.UnitStatusText`,
            ariaLabel: `${CAPACITY_LIMIT_TYPES.ABOVE_THRESHOLD}.UnitStatusAriaLabel`,
        },
        [`${CAPACITY_LIMIT_TYPES.THROTTLED}`]: {
            icon: <WarningIcon filled color="#C50F1F" data-testid="throttled-warning-icon" />,
            status: `${CAPACITY_LIMIT_TYPES.THROTTLED}.UnitStatusText`,
            ariaLabel: `${CAPACITY_LIMIT_TYPES.THROTTLED}.UnitStatusAriaLabel`,
            supplementary: `${CAPACITY_LIMIT_TYPES.THROTTLED}.Supplementary`,
        },
    };
};

/**
 * Calculates and returns the consumption usage data based on the provided hourly capacity usage.
 *
 * @param {HourlyCapacityUsage} item - The hourly capacity usage data.
 * @returns {ConsumptionBasedChartUsageData} The calculated consumption usage data, including:
 * - `usageBelowThreshold`: The usage below the threshold capacity.
 * - `usageAboveThreshold`: The usage above the threshold capacity but below the max allowed capacity.
 * - `usageThrottled`: The usage that exceeds the max allowed capacity.
 * - `usageBucket`: The category of usage based on the capacity limits (below threshold, above threshold, or throttled).
 */
export const getConsumptionUsageData = (
    item: HourlyCapacityUsage,
): ConsumptionBasedChartUsageData => {
    const thresholdCapacity = roundToDecimalPlaces(
        getThresholdCapacity(item.maxAllowedCapacity),
        USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
    );

    const roundedUsedCapacity = roundToDecimalPlaces(
        item.usedCapacity,
        USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
    );
    const maxAllowedCapacity = item.maxAllowedCapacity;

    let usageBelowThreshold;
    let usageAboveThreshold;
    let usageThrottled;
    let usageBucket;

    // If usage within threshold
    if (roundedUsedCapacity < thresholdCapacity) {
        usageBelowThreshold = roundToDecimalPlaces(
            item.usedCapacity,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );
        usageAboveThreshold = 0;
        usageThrottled = 0;
        usageBucket = CAPACITY_LIMIT_TYPES.BELOW_THRESHOLD;
    }
    // If usage between threshold and max allowed capacity
    else if (roundedUsedCapacity >= thresholdCapacity && roundedUsedCapacity < maxAllowedCapacity) {
        usageBelowThreshold = thresholdCapacity;
        usageAboveThreshold = roundToDecimalPlaces(
            roundedUsedCapacity - thresholdCapacity,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );
        usageThrottled = 0;
        usageBucket = CAPACITY_LIMIT_TYPES.ABOVE_THRESHOLD;
    }
    // If usage exceeds max allowed capacity
    else {
        usageBelowThreshold = thresholdCapacity;
        usageAboveThreshold = roundToDecimalPlaces(
            maxAllowedCapacity - thresholdCapacity,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );
        usageThrottled = roundToDecimalPlaces(
            roundedUsedCapacity - maxAllowedCapacity,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );
        usageBucket = CAPACITY_LIMIT_TYPES.THROTTLED;
    }

    return {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket};
};

/**
 * Generates chart data for consumption based on usage thresholds.
 *
 * @param aboveThresholdUsage - The usage amount above the threshold.
 * @param belowThresholdUsage - The usage amount below the threshold.
 * @param throttledUsage - The usage amount that is throttled.
 * @returns An array of chart data points for the consumption model based chart.
 */
export const generateConsumptionChartData = (
    aboveThresholdUsage: number,
    belowThresholdUsage: number,
    throttledUsage: number,
): IVSChartDataPoint[] => {
    return [
        {
            legend: CAPACITY_BUCKETS.BELOW_THRESHOLD,
            data: belowThresholdUsage,
            color: tokens.colorBrandForegroundLink,
        },
        {
            legend: CAPACITY_BUCKETS.ABOVE_THRESHOLD,
            data: aboveThresholdUsage,
            color: tokens.colorPalettePeachBorderActive,
        },
        {
            legend: CAPACITY_BUCKETS.THROTTLED,
            data: throttledUsage,
            color: tokens.colorStatusDangerBackground3,
        },
    ];
};

const getThresholdCapacity = (maxAllowedCapacity: number): number =>
    CAPACITY_THRESHOLD_PERCENTAGE * maxAllowedCapacity;
