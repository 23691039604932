import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    numericSection: {
        display: 'flex',
        flexDirection: 'row',

        '& > div:not(:last-child)': {
            marginRight: tokens.spacingHorizontalL,
        },
    },
    spinButtonSection: {
        width: '180px !important',

        '& > div:last-child': {
            color: tokens.colorPaletteCranberryBorderActive,

            '& > span:first-child': {
                position: 'relative',
                top: '3px',
            },
        },
    },
    spinButton: {
        height: '60px',
        fontSize: tokens.fontSizeHero800,

        '& .fui-SpinButton__incrementButton': {
            marginTop: tokens.spacingVerticalMNudge,
        },
    },
    estimatedMonthlyCostSection: {
        '& > div:first-child': {
            alignItems: 'center',
        },
    },
    costPerMonth: {
        fontSize: tokens.fontSizeBase600,
        lineHeight: tokens.spacingVerticalXXXL,
        fontWeight: tokens.fontSizeBase600,
    },
    overageContentSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: tokens.spacingVerticalXXL,

        '> div:first-child': {
            marginBottom: tokens.spacingVerticalXS,
        },
    },
    changeCapacityOverageSection: {
        marginTop: '36px',
        textAlign: 'left',
    },
});
