import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import useClasses from './MultiSelect.styles';
import {
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Button,
    Combobox,
    ComboboxProps,
    Option,
    useId,
    Badge,
} from '@fluentui/react-components';
import MultiSelect from './MultiSelect';
import {
    IdValueData,
    PIPE_DELIMITER,
    UsageMonitoringFilterPanelProps,
} from '@/components/ui/Filter/Filter.types';
import {Dismiss12Regular} from '@fluentui/react-icons';
import PersonaElement from '@/components/ui/Persona/PersonaElement';
import {UserProfile} from '@/api/user/user.types';
import {useTranslation} from 'react-i18next';
import {FilterPanelLabels} from '../UsageDashboard.constants';

const UsageMonitoringFilterPanel: React.FC<UsageMonitoringFilterPanelProps> = ({
    order,
    title,
    data,
    showMultiselect,
    showMultiSelectFilterSearch,
    showUserFilterSearch,
    selectedOptions = [],
    onSelectionChange = () => {},
    onReset = () => {},
}: UsageMonitoringFilterPanelProps) => {
    const classes = useClasses();
    const {t: tAdmin} = useTranslation('admin');
    const comboId = useId('combo-multi');
    const selectedListId = `${comboId}-selection`;
    const accordionElement = useRef<HTMLDivElement>(null);

    const selectedListRef = useRef<HTMLUListElement>(null);
    const comboboxInputRef = useRef<HTMLInputElement>(null);

    const [selectedComboOptions, setSelectedComboOptions] = useState<string[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [query, setQuery] = useState<string>('');

    // Sync selectedComboOptions with selectedOptions prop
    useEffect(() => {
        if (showUserFilterSearch) {
            setSelectedComboOptions(selectedOptions);
        }
    }, [selectedOptions, showUserFilterSearch]);

    const handleComboBoxSelect: ComboboxProps['onOptionSelect'] = (event, selectData) => {
        const selectedOptionsWithIdAndName = selectData.selectedOptions.map((option) => {
            const split = option.split(PIPE_DELIMITER);
            return `${split[0]}${PIPE_DELIMITER}${split[1]}`; // Assuming the format is "id|displayName"
        });
        setSelectedComboOptions(selectedOptionsWithIdAndName);
        onSelectionChange(selectedOptionsWithIdAndName);
    };

    const onTagClick = (option: string, index: number) => {
        const newSelectedOptions = selectedComboOptions.filter((o) => o !== option);
        setSelectedComboOptions(newSelectedOptions);
        onSelectionChange(newSelectedOptions);

        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
            `#${comboId}-remove-${indexToFocus}`,
        );
        if (optionToFocus) {
            (optionToFocus as HTMLButtonElement).focus();
        } else {
            comboboxInputRef.current?.focus();
        }
    };

    const renderComboboxOptions = useCallback(() => {
        if (Array.isArray(data) && typeof data[0] === 'object') {
            return (data as UserProfile[])
                .filter((option) => option.displayName.toLowerCase().includes(query.toLowerCase()))
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map((option) => (
                    <Option
                        data-testId="search-filter-user"
                        key={option.id}
                        value={`${option.id}|${option.displayName}`}
                        text={option.displayName}
                    >
                        <div>
                            <PersonaElement profile={option} />
                        </div>
                    </Option>
                ));
        }
        return null;
    }, [data, query]);

    const sortedData = useMemo(() => {
        if (Array.isArray(data) && typeof data[0] === 'string') {
            return [...(data as string[])].sort((a, b) => a.localeCompare(b)); // Sort options by string value after copying the array
        } else if (
            Array.isArray(data) &&
            typeof data[0] === 'object' &&
            data[0].hasOwnProperty('value')
        ) {
            return [...(data as IdValueData[])].sort((a, b) => a.value.localeCompare(b.value));
        }
        return data;
    }, [data]);

    const labelledBy = selectedComboOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

    const selectedCount = showUserFilterSearch
        ? selectedComboOptions.length
        : selectedOptions.length;

    const handleHeaderClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <AccordionItem value={order} className={classes.accordionItem}>
            <AccordionHeader
                className={classes.accordionHeader}
                expandIconPosition="end"
                onClick={handleHeaderClick}
                ref={accordionElement}
            >
                <div className={classes.accordionHeaderCol}>
                    {title}
                    {!isExpanded && selectedCount > 0 && (
                        <Badge appearance="filled" color="brand" className={classes.badge}>
                            {selectedCount} {tAdmin(FilterPanelLabels.Selected)}
                        </Badge>
                    )}
                </div>
                <Button
                    appearance="transparent"
                    disabled={selectedCount === 0}
                    onClick={(e) => {
                        e.stopPropagation();
                        // focus on accordionElement
                        accordionElement?.current?.querySelector('button')?.focus();
                        onReset();
                    }}
                >
                    {tAdmin(FilterPanelLabels.Reset)}
                </Button>
            </AccordionHeader>
            <AccordionPanel>
                {showMultiselect && (
                    <MultiSelect
                        options={sortedData as IdValueData[]}
                        showFilterSearch={showMultiSelectFilterSearch}
                        selectedOptions={selectedOptions}
                        onSelectionChange={onSelectionChange}
                    />
                )}
                {showUserFilterSearch && (
                    <>
                        {selectedComboOptions.length > 0 && (
                            <ul
                                id={selectedListId}
                                className={classes.tagsList}
                                ref={selectedListRef}
                            >
                                <span id={`${comboId}-remove`} hidden>
                                    {tAdmin(FilterPanelLabels.Remove)}
                                </span>
                                {selectedComboOptions.map((option, i) => {
                                    const displayName = option.split('|')[1]; // Extract the display name from the format "id|displayName"
                                    return (
                                        <li key={option}>
                                            <Button
                                                size="small"
                                                shape="circular"
                                                appearance="primary"
                                                icon={<Dismiss12Regular />}
                                                iconPosition="after"
                                                onClick={() => onTagClick(option, i)}
                                                id={`${comboId}-remove-${i}`}
                                                aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                                            >
                                                {displayName}
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <Combobox
                            className={classes.searchInput}
                            aria-labelledby={labelledBy}
                            multiselect={true}
                            placeholder={tAdmin(FilterPanelLabels.SelectUsers)}
                            selectedOptions={selectedComboOptions}
                            onOptionSelect={handleComboBoxSelect}
                            onChange={(ev) => setQuery(ev.target.value)}
                            ref={comboboxInputRef}
                            autoComplete="off"
                            value={query}
                            listbox={{className: classes.comboOptionGroup}}
                        >
                            {renderComboboxOptions()}
                        </Combobox>
                    </>
                )}
            </AccordionPanel>
        </AccordionItem>
    );
};

export default UsageMonitoringFilterPanel;
