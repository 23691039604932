import {useState, useEffect} from 'react';
import useClasses from '../ManageWorkspaces.styles';
import {TableCellLayout} from '@fluentui/react-components';
import {Workspace} from '@/api/workspaces';
import {useGetRoleMembers} from '@/api/rbac';
import {PolicyWorkspace} from '@/api/rbac/rbac.types';
import WorkspaceRoleMembers from './WorkspaceRoleMembers';

interface MembersColumnProps {
    workspace: Workspace;
    getOwnerMembers: boolean;
}
export default function MembersColumn({workspace, getOwnerMembers}: MembersColumnProps) {
    const classes = useClasses();
    // members logic
    const {
        data: dataShareResponse,
        isFetched: dataShareFetched,
        isSuccess: dataShareIsSuccessful,
        isRefetching: dataShareRefetching,
    } = useGetRoleMembers(workspace.name || '', {enabled: !!workspace.name});
    const [policyReferenceName, setPolicyReferenceName] = useState<string>('');

    useEffect(() => {
        if (dataShareIsSuccessful) {
            if (dataShareResponse.properties.entity.referenceName!!) {
                setPolicyReferenceName(dataShareResponse.properties.entity.referenceName);
            }
        }
    }, [dataShareFetched, dataShareIsSuccessful]);

    return (
        <TableCellLayout>
            <div className={classes.overflowAvatars}>
                <WorkspaceRoleMembers
                    workspace={workspace}
                    workspacePolicy={dataShareResponse as PolicyWorkspace}
                    werePoliciesSuccessfullyFetched={dataShareIsSuccessful}
                    dataRefetching={dataShareRefetching}
                    policyReferenceName={policyReferenceName}
                    getOwnerMembers={getOwnerMembers}
                />
            </div>
        </TableCellLayout>
    );
}
