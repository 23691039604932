import {customFetch, CustomFetchOptions, FeaturedFetch} from './api';
import {useECSFlags} from '@/components/FeatureFlags';

/**
 * Hook implementation of customFetch from api.ts
 * This hook adds the featureFlags from the FeatureFlagProvider context
 * and passes those flags into the customFetch function
 */
function useFetch() {
    // returning null
    const {featureFlags} = useECSFlags();

    /**  A wrapper around our fetch function that includes feature flags */
    const featuredFetch: FeaturedFetch = async <T>(
        url: string,
        options?: CustomFetchOptions,
        returnResponse: boolean = false,
        redirectCount: number = 0,
    ): Promise<T> => {
        const optionsWithFeatureFlags = {
            ...options,
            featureFlags: featureFlags || [],
        };

        return customFetch<T>(
            url,
            {
                ...optionsWithFeatureFlags,
            },
            returnResponse,
            redirectCount,
        );
    };

    return {
        customFetch: featuredFetch,
    };
}

export default useFetch;
