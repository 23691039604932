import {useFeatureFlag} from '@/api/user';
import {useTranslation} from 'react-i18next';
import useClasses from './UsageDashboard.styles';
import MedeinaFeatures from '@/util/features';
import {NumericDisplayEmphasis, SmallTitle} from '@/components/ui/Text';
import {WORKSPACE_USAGE} from './UsageDashboard.constants';
import {
    Button,
    Dialog,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Toast,
    Toaster,
    ToastTitle,
    useId,
    useToastController,
} from '@fluentui/react-components';
import {useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetWorkspaces, Workspace} from '@/api/workspaces';
import {Capacity, useGetCapacities} from '@/api/capacities';
import {WorkspaceCapacity} from '@/api/workspaces/workspaces.types';
import {WorkspaceForm} from '../../workspaces';
import DisconnectCapacityDialog from '../../workspaces/ManageWorkspaces/DisconnectCapacityDialog';
import {AddIcon} from '@/components/ui/icons';
import usePutWorkspace from '@/api/workspaces/usePutWorkspace';
import WorkspacesLoadingOverlay from '../../workspaces/ManageWorkspaces/WorkspacesLoadingOverlay';

interface CapacityAllocationSectionProps {
    disableChangeCapacity: boolean;
    workspaceDisplayName: string;
    selectedCapacity: string;
    setWorkspaceDisplayName: (workspace: string) => void;
    handleSwitchCapacitySuccess: () => void;
}

export default function CapacityAllocationSection(props: CapacityAllocationSectionProps) {
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {t} = useTranslation('admin');

    const classes = useClasses();
    const {data: data, isSuccess: isGetCapacitiesSuccess} = useGetCapacities({});
    const {data: workspacesData} = useGetWorkspaces();
    const putWorkspace = usePutWorkspace();

    const navigate = useNavigate();

    // multi-workspaces
    const {availableWorkspaces, unavailableWorkspaces} = useMemo(() => {
        let available: Workspace[] = [];
        let unavailable: Workspace[] = [];
        if (workspacesData?.value) {
            for (const workspace of workspacesData.value) {
                if (
                    data?.value.find(
                        (capacity: Capacity) =>
                            capacity.name === workspace?.capacity?.referenceName,
                    )
                ) {
                    unavailable.push(workspace);
                } else {
                    available.push(workspace);
                }
            }
        }
        return {availableWorkspaces: available, unavailableWorkspaces: unavailable};
    }, [workspacesData, data?.value]);
    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
    const [tempSelectedWorkspace, setTempSelectedWorkspace] = useState<string | null>(null);
    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);
    const [attachedCapacity, setAttachedCapacity] = useState<WorkspaceCapacity>();
    const handleDisconnectConfirm = () => {
        // update workspace
        const selectedWorkspace = workspacesData?.value.find(
            (workspace: Workspace) => workspace.name === tempSelectedWorkspace,
        );
        handleAvailableWorkspaceClick(selectedWorkspace?.name || '');
        setDisconnectCapacityDialogOpen(false);
    };
    const handleInUseWorkspaceClick = (workspaceSelected: string) => {
        setTempSelectedWorkspace(workspaceSelected);
        // find the capacity attached to the workspace
        const tempAttachedCapacity = workspacesData?.value.find(
            (workspace: Workspace) => workspace.name === workspaceSelected,
        )?.capacity;
        if (tempAttachedCapacity) {
            setAttachedCapacity(tempAttachedCapacity);
            setDisconnectCapacityDialogOpen(true);
        }
    };
    const handleAvailableWorkspaceClick = (workspaceSelected: string) => {
        setOverlay(true);
        // update workspace
        const selectedWorkspace = workspacesData?.value.find(
            (workspace: Workspace) => workspace.name === workspaceSelected,
        );
        if (selectedWorkspace) {
            putWorkspace.mutate(
                {
                    request: {
                        ...selectedWorkspace,
                        name: selectedWorkspace.name,
                        capacity: {
                            type: 'CapacityReference',
                            referenceName: props.selectedCapacity,
                        },
                    },
                    targetWorkspace: selectedWorkspace,
                },
                {
                    onSuccess: () => {
                        console.log('Workspace updated');
                        props.setWorkspaceDisplayName(selectedWorkspace.name);
                        setOverlay(false);
                        notify();
                        props.handleSwitchCapacitySuccess();
                    },
                    onError: (error) => {
                        console.error('Error updating workspace', error);
                        setOverlay(false);
                        notifyFail();
                    },
                },
            );
        }
    };
    // success toast
    const toasterId = useId('toaster');
    const {dispatchToast} = useToastController(toasterId);
    const notify = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{t('ManageWorkspaces.CapacitySetSuccess')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'success'},
        );
    };
    // fail toast
    const notifyFail = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{t('ManageWorkspaces.CapacitySetFailure')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'error'},
        );
    };
    // manage workspaces button ref
    const manageWorkspacesButtonRef = useRef<HTMLButtonElement>(null);
    const [overlay, setOverlay] = useState<boolean>(false);
    return (
        <>
            <div className={classes.metaData}>
                {isWorkspacesTestingEnabled && (
                    <>
                        <div>
                            <SmallTitle>{t(WORKSPACE_USAGE)}</SmallTitle>
                        </div>
                        <div className={classes.capacityValueContainer}>
                            {props.workspaceDisplayName ? (
                                <NumericDisplayEmphasis>
                                    {props.workspaceDisplayName}
                                </NumericDisplayEmphasis>
                            ) : (
                                <div className={classes.workspaceSelect}>
                                    <Menu>
                                        <MenuTrigger disableButtonEnhancement>
                                            <MenuButton
                                                data-testid="workspace-menu-button"
                                                className={classes.errorMenuButton}
                                            >
                                                {t(
                                                    'ManageWorkspaces.MenuButtons.NoWorkspaceSelected',
                                                )}
                                            </MenuButton>
                                        </MenuTrigger>
                                        <MenuPopover>
                                            <MenuList>
                                                <MenuGroup>
                                                    <MenuGroupHeader>
                                                        {t('Workspaces.Form.Available')}
                                                    </MenuGroupHeader>
                                                    {availableWorkspaces.map(
                                                        (workspace: Workspace, index: number) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    icon={
                                                                        <div
                                                                            className={
                                                                                classes.fillerIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={() =>
                                                                        handleAvailableWorkspaceClick(
                                                                            workspace.name,
                                                                        )
                                                                    }
                                                                >
                                                                    {workspace.name}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                                    <MenuGroupHeader>
                                                        {t('Workspaces.Form.InUse')}
                                                    </MenuGroupHeader>
                                                    {unavailableWorkspaces.map(
                                                        (workspace: Workspace, index: number) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    icon={
                                                                        <div
                                                                            className={
                                                                                classes.fillerIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={() =>
                                                                        handleInUseWorkspaceClick(
                                                                            workspace.name,
                                                                        )
                                                                    }
                                                                >
                                                                    {workspace.name}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}
                                                </MenuGroup>
                                                <MenuDivider />
                                                <MenuGroup>
                                                    <MenuItem
                                                        icon={<AddIcon />}
                                                        data-testid="new-workspace-menu-item"
                                                        onClick={() => setWorkspaceDialogOpen(true)}
                                                    >
                                                        {t(
                                                            'ManageWorkspaces.MenuButtons.NewWorkspace',
                                                        )}
                                                    </MenuItem>
                                                </MenuGroup>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>
                                </div>
                            )}
                        </div>
                        <Button
                            appearance="secondary"
                            onClick={() => navigate('/manage-workspaces')}
                            ref={manageWorkspacesButtonRef}
                            disabled={props.disableChangeCapacity}
                            data-testid="manage-workspaces-button"
                        >
                            {t('ManageWorkspaces.MenuButtons.ManageWorkspaces')}
                        </Button>
                        <Dialog
                            open={workspaceDialogOpen}
                            onOpenChange={(event, data) => setWorkspaceDialogOpen(data.open)}
                        >
                            <WorkspaceForm
                                mode="create"
                                onClose={() => setWorkspaceDialogOpen(false)}
                            />
                        </Dialog>
                        <DisconnectCapacityDialog
                            title={t('ManageWorkspaces.DisconnectWorkspaceDialog.Title', {
                                0: tempSelectedWorkspace,
                            })}
                            descriptionShort={t(
                                'ManageWorkspaces.DisconnectWorkspaceDialog.DescriptionShort',
                            )}
                            description={t(
                                'ManageWorkspaces.DisconnectWorkspaceDialog.Description',
                                {
                                    0: tempSelectedWorkspace,
                                    1: props.selectedCapacity,
                                    2: attachedCapacity?.referenceName,
                                },
                            )}
                            switchButtonLabel={t(
                                'ManageWorkspaces.DisconnectWorkspaceDialog.SwitchButton',
                            )}
                            isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                            newCapacityName={props.selectedCapacity || ''}
                            workspaceName={tempSelectedWorkspace || ''}
                            onClose={() => {
                                setDisconnectCapacityDialogOpen(false);
                            }}
                            onConfirm={handleDisconnectConfirm}
                        />
                        {overlay && (
                            <WorkspacesLoadingOverlay
                                label={t('ManageWorkspaces.SettingCapacity')}
                            />
                        )}
                        <Toaster toasterId={toasterId} />
                    </>
                )}
            </div>
        </>
    );
}
