import {useMutation} from '@tanstack/react-query';
import {
    OverageState,
    CreateCapacityRequest,
    CreateCapacityResponse,
} from '../capacities/capacities.types';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import MedeinaVariables from '@/util/variables';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export default function useCreateCapacity(
    subscriptionId: string,
    resourceGroup: string,
    capacityName: string,
) {
    const {customFetch} = useFetch();
    let isOverageEnabled = useFeatureFlag(MedeinaFeatures.EnableUsageDashboardOverage) as boolean;
    let apiVersion = '2023-12-01-preview';
    return useMutation({
        mutationFn: async (body: CreateCapacityRequest) => {
            if (isOverageEnabled) {
                apiVersion = '2024-11-01-preview';
            }
            if (!isOverageEnabled) {
                body.properties.overageState = OverageState.None;
            }
            if (body.properties.overageState === OverageState.None) {
                delete body.properties.overageAmount;
            }
            const response = await customFetch<Response>(
                `${MedeinaVariables.ArmUri}/subscriptions/${subscriptionId}/resourceGroups/${resourceGroup}/providers/Microsoft.SecurityCopilot/capacities/${capacityName}?api-version=${apiVersion}`,
                {
                    method: 'PUT',
                    scopes: RESOURCE_SCOPES.ARM,
                    body,
                },
                true,
            );

            if (!response.ok) {
                // we want to differentiate between response codes so that the frontend knows what to show
                const code = response.status;
                if (code === 409) {
                    throw new Error('Capacity already exists');
                } else if (code === 403) {
                    throw new Error(
                        `User does not have permission to create capacity: ${response.statusText}`,
                    );
                } else {
                    throw new Error(`Failed to create capacity: ${response.statusText}`);
                }
            }

            const data = await response.json();
            return data.value as CreateCapacityResponse;
        },
        retry: 0,
    });
}
