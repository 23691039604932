import React, {useEffect} from 'react';
import useGetUserFeatureFlags from '@/api/user/useGetUserFeatureFlags';
import {UserFeatures} from '@/api/user/user.types';
import {msalInstance, isUserSignedIn} from '@/util/msal/authConfig';
import featureFlags from './staticFeatureFlags';
import {useTrackEvent, MedeinaTelemetryEvent, MedeinaEvent} from '@/api/telemetry';

export const FeatureFlagContext = React.createContext<UserFeatures>(null!);

const emptyFeatureFlags: UserFeatures = {
    featureFlags: [],
    isEmailTriggerAllowed: false,
};
/**
 * a component that makes the call to /users/features and provides the feature flags to the rest of the app
 * this blocks the rest of the application from loading until the feature flags are available
 * it allows rendering the application if there is no user Authenticated so the rest of the application boots
 * @param props
 * @returns
 */

function FeatureFlagProvider(props: any) {
    // check for URL parameter
    const isAuthenticated = isUserSignedIn(msalInstance);
    const {mutate: trackEvent} = useTrackEvent();
    const {data, isLoading, isFetched, isError} = useGetUserFeatureFlags({
        enabled: isAuthenticated,
        onSettled: (data, error) => {
            if (error) {
                trackEvent({
                    eventType: MedeinaEvent.DataEvent,
                    name: MedeinaTelemetryEvent.App.FeatureFlagsFallbackUsed,
                    data: {
                        message: 'Error fetching feature flags from /users/features',
                    },
                });
            }
            if (data?.featureFlags.length === 0) {
                // console.error('No feature flags returned from ECS');
                trackEvent({
                    eventType: MedeinaEvent.DataEvent,
                    name: MedeinaTelemetryEvent.App.FeatureFlagsFallbackUsed,
                    data: {
                        message: 'No feature flags returned from ECS to /users/features',
                    },
                });
            }
        },
    });

    /** In the case where ECS fails to respond or for whatever reason we get empty flags fall back to our static list of flags */
    const useFallbackFlags =
        isAuthenticated && !isLoading && (isError || !data?.featureFlags.length);
    const oldFallbackFlags = isError && isAuthenticated;

    useEffect(() => {
        if (useFallbackFlags) {
            trackEvent({
                eventType: MedeinaEvent.DataEvent,
                name: MedeinaTelemetryEvent.App.FeatureFlagsFallbackUsed,
                data: {
                    message: 'Falling back to static feature flags',
                    apiError: isError,
                    featureFlagCount: data?.featureFlags.length || 0,
                    newFallbackValue: useFallbackFlags,
                    oldFallbackValue: oldFallbackFlags,
                    isAuthenticated,
                    isLoading,
                },
            });
        }
        // Don't track trackevent in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useFallbackFlags]);

    // console.log({data, isLoading, isFetched, isError, isAuthenticated, useFallbackFlags});
    return isLoading && isAuthenticated && !data ? null : (
        <FeatureFlagContext.Provider
            value={
                oldFallbackFlags
                    ? {featureFlags: featureFlags, isEmailTriggerAllowed: false}
                    : data || emptyFeatureFlags
            }
        >
            {props.children}
        </FeatureFlagContext.Provider>
    );
}

export default FeatureFlagProvider;
