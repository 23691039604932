import React, {useEffect, useState} from 'react';
import {ReactFlow} from '@xyflow/react';
import {useTranslation} from 'react-i18next';
import {useGetSession, useIsUserSessionOwner} from '@/api/sessions';
import useSidePanel from '@/components/ui/SidePanel/useSidePanel';
import {useLayout} from '@/components/ui/Layout';
import {useViewportSize} from '@/components/ui/Grid';
import {AgentProps} from './Agent.types';
import {useGetPrompts} from '@/api/prompts';
import {useGetEvaluationSkillInvocations} from '@/api/evaluations';
import {InvestigationPanel} from '../investigations';
import {Spinner} from '@fluentui/react-components';
import AgentNode from './AgentNode';
import AgentEdge from './AgentEdge';
import AgentNodeDetailsModal from './AgentNodeDetailsModal';
import {convertToNodesAndEdges} from './ConvertToNodesAndEdges';
import useClasses from './Agent.styles';

import '@xyflow/react/dist/style.css';

export * from './Agent.types';

export default function Agent({sessionId}: AgentProps) {
    const {t} = useTranslation(['session']);
    const classes = useClasses();
    const {data: session, isLoading, isError, refetch: getSession} = useGetSession({sessionId});
    const {
        data: prompts,
        refetch: getPrompts,
        isLoading: isGetPromptsLoading,
    } = useGetPrompts({sessionId});

    const {isUserSessionOwner: isUserSessionOwnerResponse} = useIsUserSessionOwner(sessionId);
    const [isSessionOwner, setIsSessionOwner] = useState<boolean>(true);
    const [selectedNodeData, setSelectedNodeData] = useState<any>(null);
    const [lastFetchTime, setLastFetchTime] = useState<number>(Date.now());

    useEffect(() => {
        if (isError && !isUserSessionOwnerResponse) {
            getSession();
            getPrompts();
            setIsSessionOwner(false);
        }
    }, [isError, isUserSessionOwnerResponse, getSession, getPrompts]);

    const {sidePanel} = useLayout();
    const screen = useViewportSize();

    const DELAY = 6000;

    useEffect(() => {
        if (screen.sm || screen.md) {
            sidePanel?.toggleClosed?.();
        }
    }, [screen, sidePanel]);

    // Fetch evaluation skill invocations
    const evaluationIds = prompts?.value?.flatMap((prompt: any) => prompt.latestEvaluationId) || [];
    const promptIds = prompts?.value?.map((prompt: any) => prompt.promptId) || [];
    const evaluationsSkillInvocations = useGetEvaluationSkillInvocations({
        sessionId,
        promptIds,
        evaluationIds,
        enabled: !!session,
    });

    // Refetch evaluationsSkillInvocations when panning or scrolling ends and has been at least DELAY ms since last fetch
    const handleMoveEnd = () => {
        const currentTime = Date.now();
        if (session && currentTime - lastFetchTime >= DELAY) {
            evaluationsSkillInvocations?.forEach((invocation) => invocation.refetch());
            setLastFetchTime(currentTime);
        }
    };

    const handleNodeClick = (id: string, data: any) => {
        setSelectedNodeData(data);
    };

    const evaluationData = evaluationsSkillInvocations.map((invocation) => ({
        data: {value: invocation.data?.value || []},
    }));
    const {nodes, edges} =
        session && prompts
            ? convertToNodesAndEdges(session, prompts, evaluationData, handleNodeClick)
            : {nodes: [], edges: []};

    return (
        <div className={classes.container} data-testid="agent-view">
            {isLoading && !isError ? (
                <Spinner />
            ) : (
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={{customNode: AgentNode, evaluationNode: AgentNode}}
                    edgeTypes={{customEdge: AgentEdge}}
                    onMoveEnd={handleMoveEnd}
                    minZoom={0.1}
                ></ReactFlow>
            )}
            <AgentNodeDetailsModal
                nodeData={selectedNodeData}
                onClose={() => setSelectedNodeData(null)}
            />
        </div>
    );
}
